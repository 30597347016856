// Core asset
require("file-loader?name=asset-symbols/[name].png!./bts.png");

// BitAssets
require("file-loader?name=asset-symbols/[name].png!./usd.png");
require("file-loader?name=asset-symbols/[name].png!./eur.png");
require("file-loader?name=asset-symbols/[name].png!./cny.png");
require("file-loader?name=asset-symbols/[name].png!./gold.png");
require("file-loader?name=asset-symbols/[name].png!./btc.png");
require("file-loader?name=asset-symbols/silver.png!./silver.png");
require("file-loader?name=asset-symbols/[name].png!./krw.png");
require("file-loader?name=asset-symbols/[name].png!./mxn.png");
require("file-loader?name=asset-symbols/[name].png!./jpy.png");
require("file-loader?name=asset-symbols/[name].png!./xem.png");
require("file-loader?name=asset-symbols/[name].png!./aud.png");
require("file-loader?name=asset-symbols/[name].png!./gbp.png");
require("file-loader?name=asset-symbols/[name].png!./chf.png");
require("file-loader?name=asset-symbols/[name].png!./cad.png");

//defi
require("file-loader?name=asset-symbols/[name].png!./tcgusdtlp.png");
require("file-loader?name=asset-symbols/[name].png!./tuscbtslp.png");
require("file-loader?name=asset-symbols/[name].png!./tuscusdtlp.png");
require("file-loader?name=asset-symbols/[name].png!./usdtrvnlp.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.usdtrvnlp.png");
require("file-loader?name=asset-symbols/[name].png!./crude.lvrg.png");
require("file-loader?name=asset-symbols/[name].png!./crude.egclp.png");
require("file-loader?name=asset-symbols/[name].png!./sthbtwtylp.png");
require("file-loader?name=asset-symbols/[name].png!./axaibtslp.png");
require("file-loader?name=asset-symbols/[name].png!./btcusdtlp.png");
require("file-loader?name=asset-symbols/[name].png!./egchivelp.png");
require("file-loader?name=asset-symbols/[name].png!./egcltclp.png");
require("file-loader?name=asset-symbols/[name].png!./egcbtslp.png");
require("file-loader?name=asset-symbols/[name].png!./egcbtclp.png");
require("file-loader?name=asset-symbols/[name].png!./egcusdtlp.png");
require("file-loader?name=asset-symbols/[name].png!./trdlp.png");
require("file-loader?name=asset-symbols/[name].png!./mdlbtslp.png");
require("file-loader?name=asset-symbols/[name].png!./defi.png");
require("file-loader?name=asset-symbols/[name].png!./defi.btssth.png");
require("file-loader?name=asset-symbols/[name].png!./defi.defisth.png");
require("file-loader?name=asset-symbols/[name].png!./defi.btsdefi.png");
require("file-loader?name=asset-symbols/[name].png!./defi.btsbtc.png");
require("file-loader?name=asset-symbols/[name].png!./defi.btscnc.png");
require("file-loader?name=asset-symbols/[name].png!./defi.btscny.png");
require("file-loader?name=asset-symbols/[name].png!./defi.btseur.png");
require("file-loader?name=asset-symbols/[name].png!./defi.btsusd.png");
require("file-loader?name=asset-symbols/[name].png!./defi.cnycnc.png");
require("file-loader?name=asset-symbols/[name].png!./defi.cnyeur.png");
require("file-loader?name=asset-symbols/[name].png!./defi.cnyusd.png");
require("file-loader?name=asset-symbols/[name].png!./defi.deficny.png");
require("file-loader?name=asset-symbols/[name].png!./defi.defiusd.png");
require("file-loader?name=asset-symbols/[name].png!./defi.usdeur.png");
require("file-loader?name=asset-symbols/[name].png!./defi.defixhive.png");
require("file-loader?name=asset-symbols/[name].png!./ltcl.png");
require("file-loader?name=asset-symbols/[name].png!./bchusdtlp.png");
require("file-loader?name=asset-symbols/[name].png!./btcrublp.png");
require("file-loader?name=asset-symbols/[name].png!./btsdashlp.png");
require("file-loader?name=asset-symbols/[name].png!./bitrublp.png");
require("file-loader?name=asset-symbols/[name].png!./eoswramlp.png");
require("file-loader?name=asset-symbols/[name].png!./btwty.eoswramlp.png");
require("file-loader?name=asset-symbols/[name].png!./btsemclp.png");
require("file-loader?name=asset-symbols/[name].png!./btsltclp.png");
require("file-loader?name=asset-symbols/[name].png!./ethusdtlp.png");
require("file-loader?name=asset-symbols/[name].png!./dashusdtlp.png");
require("file-loader?name=asset-symbols/[name].png!./dogeusdtlp.png");
require("file-loader?name=asset-symbols/[name].png!./ltcusdtlp.png");

// 3rd party assets
require("file-loader?name=asset-symbols/[name].png!./xch.png");
require("file-loader?name=asset-symbols/[name].png!./dpr.png");
require("file-loader?name=asset-symbols/[name].png!./ton.png");
require("file-loader?name=asset-symbols/[name].png!./wram.png");
require("file-loader?name=asset-symbols/[name].png!./dai.png");
require("file-loader?name=asset-symbols/[name].png!./btt.png");
require("file-loader?name=asset-symbols/[name].png!./rxd.png");
require("file-loader?name=asset-symbols/[name].png!./pepe.png");
require("file-loader?name=asset-symbols/[name].png!./kmd.png");
require("file-loader?name=asset-symbols/[name].png!./paxg.png");
require("file-loader?name=asset-symbols/[name].png!./zil.png");
require("file-loader?name=asset-symbols/[name].png!./flux.png");
require("file-loader?name=asset-symbols/[name].png!./gods.png");
require("file-loader?name=asset-symbols/[name].png!./vite.png");
require("file-loader?name=asset-symbols/[name].png!./asic.png");
require("file-loader?name=asset-symbols/[name].png!./etc.png");
require("file-loader?name=asset-symbols/[name].png!./aur.png");
require("file-loader?name=asset-symbols/[name].png!./mana.png");
require("file-loader?name=asset-symbols/[name].png!./bsv.png");
require("file-loader?name=asset-symbols/[name].png!./rdd.png");
require("file-loader?name=asset-symbols/[name].png!./lunareum.png");
require("file-loader?name=asset-symbols/[name].png!./lunr.png");
require("file-loader?name=asset-symbols/[name].png!./nch.png");
require("file-loader?name=asset-symbols/[name].png!./tcg.png");
require("file-loader?name=asset-symbols/[name].png!./hvq.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.hvq.png");
require("file-loader?name=asset-symbols/[name].png!./matic.png");
require("file-loader?name=asset-symbols/[name].png!./tusc.png");
require("file-loader?name=asset-symbols/[name].png!./cnc.png");
require("file-loader?name=asset-symbols/[name].png!./quint.goldback.png");
require("file-loader?name=asset-symbols/[name].png!./quint.png");
require("file-loader?name=asset-symbols/[name].png!./xaut.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.xaut.png");
require("file-loader?name=asset-symbols/[name].png!./rtm.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.rtm.png");
require("file-loader?name=asset-symbols/[name].png!./twentix.png");
require("file-loader?name=asset-symbols/[name].png!./avax.png");
require("file-loader?name=asset-symbols/[name].png!./hbd.png");
require("file-loader?name=asset-symbols/[name].png!./shib.png");
require("file-loader?name=asset-symbols/[name].png!./ppc.png");
require("file-loader?name=asset-symbols/[name].png!./ness.png");
require("file-loader?name=asset-symbols/[name].png!./prux.png");
require("file-loader?name=asset-symbols/[name].png!./eth.png");
require("file-loader?name=asset-symbols/[name].png!./steem.png");
require("file-loader?name=asset-symbols/[name].png!./obits.png");
require("file-loader?name=asset-symbols/[name].png!./btsr.png");
require("file-loader?name=asset-symbols/[name].png!./lisk.png");
require("file-loader?name=asset-symbols/[name].png!./peerplays.png");
require("file-loader?name=asset-symbols/[name].png!./icoo.png");
require("file-loader?name=asset-symbols/[name].png!./blockpay.png");
require("file-loader?name=asset-symbols/[name].png!./dash.png");
require("file-loader?name=asset-symbols/[name].png!./grc.png");
require("file-loader?name=asset-symbols/[name].png!./usdt.png");
require("file-loader?name=asset-symbols/[name].png!./usdc.png");
require("file-loader?name=asset-symbols/[name].png!./busd.png");
require("file-loader?name=asset-symbols/[name].png!./atri.png");
require("file-loader?name=asset-symbols/[name].png!./ping.png");
require("file-loader?name=asset-symbols/[name].png!./bat.png");
require("file-loader?name=asset-symbols/[name].png!./bkt.png");
require("file-loader?name=asset-symbols/[name].png!./btwty.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.btc.png");
require("file-loader?name=asset-symbols/[name].png!./hempsweet.png");
require("file-loader?name=asset-symbols/[name].png!./eos.png");
require("file-loader?name=asset-symbols/[name].png!./yoyow.png");
require("file-loader?name=asset-symbols/[name].png!./hero.png");
require("file-loader?name=asset-symbols/[name].png!./ruble.png");
require("file-loader?name=asset-symbols/[name].png!./smoke.png");
require("file-loader?name=asset-symbols/[name].png!./ppy.png");
require("file-loader?name=asset-symbols/[name].png!./stealth.png");
require("file-loader?name=asset-symbols/[name].png!./bto.png");
require("file-loader?name=asset-symbols/[name].png!./btm.png");
require("file-loader?name=asset-symbols/[name].png!./krm.png");
require("file-loader?name=asset-symbols/[name].png!./golos.png");
require("file-loader?name=asset-symbols/[name].png!./gbg.png");
require("file-loader?name=asset-symbols/[name].png!./atn.png");
require("file-loader?name=asset-symbols/[name].png!./neo.png");
require("file-loader?name=asset-symbols/[name].png!./gas.png");
require("file-loader?name=asset-symbols/[name].png!./qtum.png");
require("file-loader?name=asset-symbols/[name].png!./bkbt.png");
require("file-loader?name=asset-symbols/[name].png!./dht.png");
require("file-loader?name=asset-symbols/[name].png!./hertz.png");
require("file-loader?name=asset-symbols/[name].png!./dgb.png");
require("file-loader?name=asset-symbols/[name].png!./doge.png");
require("file-loader?name=asset-symbols/[name].png!./tusd.png");
require("file-loader?name=asset-symbols/[name].png!./zec.png");
require("file-loader?name=asset-symbols/[name].png!./waves.png");
require("file-loader?name=asset-symbols/[name].png!./zrx.png");
require("file-loader?name=asset-symbols/[name].png!./xrp.png");
require("file-loader?name=asset-symbols/[name].png!./xmr.png");
require("file-loader?name=asset-symbols/[name].png!./sbd.png");
require("file-loader?name=asset-symbols/[name].png!./sth.png");
require("file-loader?name=asset-symbols/[name].png!./kec.png");
require("file-loader?name=asset-symbols/[name].png!./ltc.png");
require("file-loader?name=asset-symbols/[name].png!./post.png");
require("file-loader?name=asset-symbols/[name].png!./bch.png");
require("file-loader?name=asset-symbols/[name].png!./bcha.png");
require("file-loader?name=asset-symbols/[name].png!./btg.png");
require("file-loader?name=asset-symbols/[name].png!./42.png");
require("file-loader?name=asset-symbols/[name].png!./xspec.png");
require("file-loader?name=asset-symbols/[name].png!./nvc.png");
require("file-loader?name=asset-symbols/[name].png!./uni.png");
require("file-loader?name=asset-symbols/[name].png!./nmc.png");
require("file-loader?name=asset-symbols/[name].png!./steep.png");
require("file-loader?name=asset-symbols/[name].png!./cof.png");
require("file-loader?name=asset-symbols/[name].png!./aht.png");
require("file-loader?name=asset-symbols/[name].png!./bbi.png");
require("file-loader?name=asset-symbols/[name].png!./xrup.png");
require("file-loader?name=asset-symbols/[name].png!./p2p.png");
require("file-loader?name=asset-symbols/[name].png!./egc.png");
require("file-loader?name=asset-symbols/[name].png!./onion.png");
require("file-loader?name=asset-symbols/[name].png!./bco.png");
require("file-loader?name=asset-symbols/[name].png!./wix.png");
require("file-loader?name=asset-symbols/[name].png!./cvcoin.png");
require("file-loader?name=asset-symbols/[name].png!./evraz.png");
require("file-loader?name=asset-symbols/[name].png!./vrc.png");

require("file-loader?name=asset-symbols/[name].png!./rvn.png");
require("file-loader?name=asset-symbols/[name].png!./btcc.png");

require("file-loader?name=asset-symbols/[name].png!./pivx.png");
require("file-loader?name=asset-symbols/[name].png!./vrm.png");
require("file-loader?name=asset-symbols/[name].png!./maid.png");
require("file-loader?name=asset-symbols/[name].png!./sonm.png");
require("file-loader?name=asset-symbols/[name].png!./trx.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.trx.png");
require("file-loader?name=asset-symbols/[name].png!./hive.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.hive.png");

require("file-loader?name=asset-symbols/[name].png!./vtc.png");
require("file-loader?name=asset-symbols/[name].png!./proton.png");
require("file-loader?name=asset-symbols/[name].png!./ardr.png");
require("file-loader?name=asset-symbols/[name].png!./wage.png");
require("file-loader?name=asset-symbols/[name].png!./ipfs.png");
require("file-loader?name=asset-symbols/[name].png!./mdl.png");
require("file-loader?name=asset-symbols/[name].png!./sky.png");
require("file-loader?name=asset-symbols/[name].png!./slb.png");
require("file-loader?name=asset-symbols/[name].png!./xsm.png");
require("file-loader?name=asset-symbols/[name].png!./grs.png");
require("file-loader?name=asset-symbols/[name].png!./nobt.png");
require("file-loader?name=asset-symbols/[name].png!./xbb.png");
require("file-loader?name=asset-symbols/[name].png!./exr.png");
require("file-loader?name=asset-symbols/[name].png!./bkbt.png");
require("file-loader?name=asset-symbols/[name].png!./applecom.png");
require("file-loader?name=asset-symbols/[name].png!./deex.png");
require("file-loader?name=asset-symbols/[name].png!./paisa.png");

require("file-loader?name=asset-symbols/[name].png!./eosdac.png");
require("file-loader?name=asset-symbols/[name].png!./cvn.png");
require("file-loader?name=asset-symbols/[name].png!./bccx.png");
require("file-loader?name=asset-symbols/[name].png!./xccx.png");
require("file-loader?name=asset-symbols/[name].png!./obyte.png");
require("file-loader?name=asset-symbols/[name].png!./iris.png");
require("file-loader?name=asset-symbols/[name].png!./hpb.png");
require("file-loader?name=asset-symbols/[name].png!./fota.png");
require("file-loader?name=asset-symbols/[name].png!./gdp.png");
require("file-loader?name=asset-symbols/[name].png!./biso.png");
require("file-loader?name=asset-symbols/[name].png!./bnb.png");
require("file-loader?name=asset-symbols/[name].png!./guap.png");
require("file-loader?name=asset-symbols/[name].png!./axai.png");
require("file-loader?name=asset-symbols/[name].png!./beos.png");

// xbts assets
require("file-loader?name=asset-symbols/[name].png!./smartholdem.dogl.png");
require("file-loader?name=asset-symbols/[name].png!./smartholdem.cnyl.png");
require("file-loader?name=asset-symbols/[name].png!./smartholdem.dalp.png");
require("file-loader?name=asset-symbols/[name].png!./smartholdem.rubl.png");
require("file-loader?name=asset-symbols/[name].png!./smartholdem.btc.png");
require("file-loader?name=asset-symbols/[name].png!./smartholdem.ethl.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.xrp.png");
require("file-loader?name=asset-symbols/[name].png!./shu.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.shu.png");
require("file-loader?name=asset-symbols/[name].png!./ht.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.ht.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.eur.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.usd.png");
require("file-loader?name=asset-symbols/[name].png!./rub.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.rub.png");
require("file-loader?name=asset-symbols/[name].png!./fil.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.fil.png");
require("file-loader?name=asset-symbols/[name].png!./usdn.png");
require("file-loader?name=asset-symbols/[name].png!./smartholdem.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.usdn.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.sch.png");
require("file-loader?name=asset-symbols/[name].png!./sch.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.trd.png");
require("file-loader?name=asset-symbols/[name].png!./trd.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.usdt.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.usdc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.busd.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.atri.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.ping.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.bat.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.tusd.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.guap.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.sth.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.btc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.doge.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.ltc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.post.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.dash.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.kec.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.bch.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.btg.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.npc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.xspec.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.nvc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.vtc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.42.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.uni.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.nmc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.vtc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.steep.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.cof.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.waves.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.aht.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.bbi.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.eth.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.xrup.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.p2p.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.egc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.onion.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.wix.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.vrc.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.vrm.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.dcnt.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.mdl.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.sky.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.slb.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.xsm.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.grs.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.nobt.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.xbb.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.exr.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.neo.png");
require("file-loader?name=asset-symbols/[name].png!./xbtsx.emc.png");
require("file-loader?name=asset-symbols/[name].png!./emc.png");

// other gateways
require("file-loader?name=asset-symbols/[name].png!./gdex.usdt.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.eos.png");
require("file-loader?name=asset-symbols/[name].png!./citadel.btc.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.bkbt.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.iq.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.bto.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.eth.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.btm.png");
require("file-loader?name=asset-symbols/[name].png!./gdex.cpc.png");
require("file-loader?name=asset-symbols/[name].png!./ngn.png");
require("file-loader?name=asset-symbols/[name].png!./crude.ngn.png");